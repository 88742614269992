<template>
  <!-- 已完成详情 -->
  <div style="width: 100%; position: relative">
    <div class="common-gaine" style="padding-top: 100px">
      <div class="common-plate-title">
        <span></span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <div class="no-payment-component">
        <OrderItem
          :data="item"
          v-for="(item, index) in goodsDetailVoList"
          :key="index"
        />
        <div class="no-payment-bottom">
          <div class="no-payment-subtotal flex-bet-cen">
            <span>{{ $trans('小计', '小计') }}</span>
            <div class="object__price" style="color: #2a9dcc">
              <div style="font-style: normal">￥</div>
              <div class="object__int">
                {{ sumMoney | product_int }}
              </div>
              <div class="object__float">
                {{ sumMoney | product_float }}
              </div>
            </div>
          </div>
        </div>
        <div class="express-info-wrap" style="margin-top: 30px">
          <div class="flex-bet-cen">
            <div class="express-info-key">
              {{ $trans('开具发票', '开具发票') }}
            </div>
            <div class="express-info-value flex-cen">
              {{
                data.invoiceId
                  ? $trans('已开具发票', '已开具发票')
                  : $trans('未开具发票', '未开具发票')
              }}
            </div>
          </div>
          <div class="flex-bet-cen">
            <div class="express-info-key">{{ $trans('优惠券', '优惠券') }}</div>
            <div class="express-info-value flex-cen">
              {{ couponVoPay }}
            </div>
          </div>
          <div class="flex-bet-cen">
            <div class="express-info-key">{{ $trans('配送费', '配送费') }}</div>
            <div class="express-info-value flex-cen">
              {{ extraCharge }}
            </div>
          </div>
          <div class="flex-bet-cen">
            <div class="express-info-key">
              {{ $trans('支付方式', '支付方式') }}
            </div>
            <div class="express-info-value flex-cen">
              {{ data.payType == 2 ? '支付宝' : '微信' }}
            </div>
          </div>
        </div>
        <div class="no-payment-bottom">
          <div class="no-payment-subtotal flex-bet-cen">
            <span>{{ $trans('实付', '实付') }}</span>
            <div class="object__price" style="color: #2a9dcc">
              <div style="font-style: normal">￥</div>
              <div class="object__int">{{ data.totalCost | product_int }}</div>
              <div class="object__float">
                {{ data.totalCost | product_float }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPayDetail } from '@/util/js/api.js'
import OrderItem from '@/components/order/OrderItem.vue'
export default {
  name: '',
  data() {
    return {
      data: {}, // 订单信息
      shangsfOrderSkuTemplate: [], // 额外费用列表
      goodsDetailVoList: [], // 商品列表
      address: {}, // 地址
    }
  },
  components: {
    OrderItem,
  },
  methods: {
    // 获取商品订单
    getNotPayDetail() {
      let params = {
        userId: this.$store.state.userId,
        batchNo: this.$route.query.batchNo,
        status: this.$route.query.status,
      }
      getPayDetail(params).then((res) => {
        // this.data = JSON.parse(JSON.stringify(res.data))
        // this.data.title = this.data.spuTitle
        // this.shangsfOrderSkuTemplate = JSON.parse(
        //   JSON.stringify(res.data.shangsfOrderSkuTemplate)
        // )
        this.goodsDetailVoList = res.data.orderInfoVoList
          ? JSON.parse(JSON.stringify(res.data.orderInfoVoList))
          : []
        this.address = res.data.shangsfAddress
          ? JSON.parse(JSON.stringify(res.data.shangsfAddress))
          : []
        this.data = JSON.parse(JSON.stringify(res.data))
        this.shangsfOrderSkuTemplate = res.data.orderInfoVoList
          ? JSON.parse(JSON.stringify(res.data.orderInfoVoList))
          : []
      })
    },
  },
  mounted() {
    this.getNotPayDetail()
  },
  computed: {
    sumMoney() {
      return this.goodsDetailVoList.length > 0
        ? this.goodsDetailVoList.reduce(
            (a, b) => a + parseInt(b.count) * b.price,
            0
          )
        : 0
    },
    // 额外费用
    extraCharge() {
      let arr = JSON.parse(JSON.stringify(this.shangsfOrderSkuTemplate))
      if (JSON.stringify(arr) == '[]') {
        return 0
      } else {
        return (
          arr.reduce((a, b) => a + b.stuffValue, 0) * parseInt(this.data.count)
        )
      }
    },
    // 使用优惠券
    couponVoPay() {
      if (this.data.couponType == 11) {
        return (
          `${this.$trans('已使用全场打', '已使用全场打')} ` +
          this.data.couponValue +
          ` ${this.$trans('折优惠券', '折优惠券')}`
        )
      } else if (this.data.couponType == 22) {
        return (
          `${this.$trans('已使用全场减', '已使用全场减')} ` +
          this.data.couponValue +
          ` ${this.$trans('元优惠券', '元优惠券')}`
        )
      } else if (this.data.couponType == 33) {
        return (
          `${this.$trans('已使用全场满', '已使用全场满')} ` +
          this.data.couponConditionAmount +
          ` ${this.$trans('元打', '元打')} ` +
          this.data.couponValue +
          ` ${this.$trans('折优惠券', '折优惠券')}`
        )
      } else if (this.data.couponType == 44) {
        return (
          `${this.$trans('已使用全场满', '已使用全场满')} ` +
          this.data.couponConditionAmount +
          ` ${this.$trans('元减', '元减')} ` +
          this.data.couponValue +
          ` ${this.$trans('元优惠券', '元优惠券')}`
        )
      } else if (this.data.couponType == 55) {
        return (
          `${this.$trans('已使用部分商品打', '已使用部分商品打')} ` +
          this.data.couponValue +
          ` ${this.$trans('折优惠券', '折优惠券')}`
        )
      } else if (this.data.couponType == 66) {
        return (
          `${this.$trans('已使用部分商品减', '已使用部分商品减')} ` +
          this.data.couponValue +
          ` ${this.$trans('元优惠券', '元优惠券')}`
        )
      } else if (this.data.couponType == 77) {
        return (
          `${this.$trans('已使用部分商品满', '已使用部分商品满')} ` +
          this.data.couponConditionAmount +
          ` ${this.$trans('元打', '元打')} ` +
          this.data.couponValue +
          ` ${this.$trans('折优惠券', '折优惠券')}`
        )
      } else if (this.data.couponType == 88) {
        return (
          `${this.$trans('已使用部分商品满', '已使用部分商品满')} ` +
          this.data.couponConditionAmount +
          ` ${this.$trans('元减', '元减')} ` +
          this.data.couponValue +
          ` ${this.$trans('元优惠券', '元优惠券')}`
        )
      }
      return this.$trans('暂未使用优惠券', '暂未使用优惠券')
    },
    // 消费总金额
    totalCost() {
      return (
        parseInt((this.data.price * this.data.count + this.extraCharge) * 100) /
        100
      )
    },
  },
}
</script>
<style scoped>
.no-payment-component {
  padding: 21px 21px 15px 21px;
  background: #fdfdfd;
  border: 1px solid #dfdfdf;
  margin-bottom: 14px;
  box-shadow: 2px 2px 10px #dfdfdf;
}
.no-payment-component:last-of-type {
  margin-bottom: 0;
}
.no-payment-top {
  padding-bottom: 30px;
  /* border-bottom: 1px solid #dfdfdf; */
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}
.no-payment-bottom {
  padding-top: 20px;
  border-top: 1px solid #dfdfdf;
}
.no-payment-left {
  display: flex;
  align-items: flex-start;
}
.payment-info {
  margin-left: 30px;
}
.payment-title {
  font-size: 16px;
  font-family: 'myCoolFontMedium';
  margin-bottom: 10px;
}
.payment-author {
  font-size: 16px;
  color: #595959;
  transform: scale(0.8);
  transform-origin: -30% -20%;
}
.no-payment-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.payment-number {
  width: 40px;
  height: 40px;
  background: #dfdfdf;
  border-radius: 2px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  transform: scale(0.5);
}
.no-payment-subtotal {
  font-size: 16px;
  font-family: 'myCoolFontMedium';
  margin-bottom: 24px;
}
.no-payment-order {
  font-family: 'myCoolFontRegular';
  color: #666666;
  margin-bottom: 50px;
  font-size: 16px;
}
.no-payment-order > span {
  transform: scale(0.8);
  transform-origin: left;
}
.no-payment-serial {
  transform-origin: right;
  transform: scale(0.8);
  color: #666666;
}
.no-payment-oper {
  justify-content: flex-end;
  transform-origin: right;
  transform: scale(0.6);
}
.no-payment-oper .btn-wrap {
  width: 120px;
  height: 60px;
  border-radius: 7px;
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  color: #999999;
  font-size: 16px;
  flex-shrink: 0;
}
.no-payment-oper .btn-wrap.btn-fill {
  border: none;
  color: #ffffff;
}
.no-payment-oper .btn-wrap:last-child {
  margin-right: 0;
}
.no-payment-select {
  width: 60px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 4px;
}
.no-payment-select input {
  margin-right: 10px;
}
.border-iframe-outer {
  display: inline-block;
  border: 1px solid #111111;
  transition: all 0.5s;
  box-shadow: 3px 3px 3px #999999;
}
.border-iframe-inner {
  border: 1px solid #ffffff;
}
/* img{
    max-width: 40px;
    max-height: 40px;
    width: auto;
    height: auto;
    display: block;
} */
.select-item {
  width: 16px;
  height: 16px;
  margin-top: 30px;
  margin-right: 20px;
}
.express-info-wrap {
  margin-top: 50px;
  padding: 7px 0px;
  margin-bottom: 50px;
}
.express-info-key {
  font-size: 14px;
  color: #666666;
  line-height: 50px;
  font-family: 'myCoolFontRegular';
}
.express-info-value {
  font-size: 14px;
  color: #666666;
  line-height: 25px;
  font-family: 'myCoolFontRegular';
}
.order-address-select {
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.address__item-top {
  font-family: 'myCoolFontMedium';
  font-size: 16px;
  line-height: 30px;
}
.address__item-tel {
  font-size: 14px;
  margin-bottom: 5px;
}
.address__item-detail {
  font-family: 'myCoolFontRegular';
  color: #444444;
  line-height: 30px;
  font-size: 14px;
  /* height: 70px;     */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon-shouhuodizhibianji {
  font-size: 30px;
  cursor: pointer;
}
</style>
